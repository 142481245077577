import './App.css';
import { isMobile } from 'react-device-detect';

function App() {
  return (
    <div className="App" style={{marginTop:isMobile?150:0}}>
      <img 
      style={{width: "100%"}}
      src="intro.jpg" 
      alt="ff1bet" 
      />
    </div>
  );
}

export default App;
